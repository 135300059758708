export default {
  id: {
    type: Number,
    visibility: false
  },
  name: {
    label: 'Fecha',
    type: String,
    visibility: false
  },
  date: {
    label: 'Fecha',
    type: Date,
    default: new Date(),
    onChange: (value, fields) => {
      fields.name.value = value ? value.slice(0, 10) : ''
    }
  },
  magna: {
    label: 'Magna',
    type: Number
  },
  premium: {
    label: 'Premium',
    type: Number
  },
  diesel: {
    label: 'Diesel',
    type: Number
  },
  gas: {
    label: 'Gas',
    type: Number
  },
  // attributes: {
  //   label: 'Atributos',
  //   type: Object,
  //   optional: true,
  //   attributes: true
  // }
};
